import { TableDataKeyEnum } from "../../components/postmatch-table-columns/postmatch-table-columns.constants";

export function getMatchingPostmatchEngagementsColumns() {
  return [
    TableDataKeyEnum.developerRecordWithLink,
    TableDataKeyEnum.companyNameWithLink,
    TableDataKeyEnum.jobTitleWithLink,
    TableDataKeyEnum.opportunityLink,
    TableDataKeyEnum.developerRate,
    TableDataKeyEnum.customerRate,
    TableDataKeyEnum.billedHours,
    TableDataKeyEnum.startDate,
    TableDataKeyEnum.endDate,
    TableDataKeyEnum.duration,
    TableDataKeyEnum.internalStatus,
    TableDataKeyEnum.externalStatus,
    TableDataKeyEnum.locationText,
    TableDataKeyEnum.customerContactEmail,
    TableDataKeyEnum.accountExecutiveEmail,
    TableDataKeyEnum.salesRepEmail,
    TableDataKeyEnum.personalEmail,
    TableDataKeyEnum.turingEmail,
    TableDataKeyEnum.feedback,
  ];
}

export function getMatchingCompanyColumns() {
  const columnsToRemove = new Set([TableDataKeyEnum.companyNameWithLink]);
  return getMatchingPostmatchEngagementsColumns().filter(
    (column) => !columnsToRemove.has(column),
  );
}

export function getSelfServePostmatchEngagementsColumns() {
  return [
    TableDataKeyEnum.developerRecord,
    TableDataKeyEnum.customerRate,
    TableDataKeyEnum.billedHours,
    TableDataKeyEnum.startDate,
    TableDataKeyEnum.endDate,
    TableDataKeyEnum.duration,
    TableDataKeyEnum.externalStatus,
    TableDataKeyEnum.locationText,
    TableDataKeyEnum.customerContactEmail,
    TableDataKeyEnum.accountExecutiveEmail,
  ];
}

export function getSelfServePostmatchEngagementsColumnsWithFeedback() {
  return [
    ...getSelfServePostmatchEngagementsColumns(),
    TableDataKeyEnum.feedback,
  ];
}
