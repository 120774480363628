export enum TableDataKeyEnum {
  "developerRecordWithLink" = "developerRecordWithLink",
  "developerName" = "developerName",
  "companyNameWithLink" = "companyNameWithLink",
  "companyName" = "companyName",
  "jobTitleWithLink" = "jobTitleWithLink",
  "jobTitle" = "jobTitle",
  "opportunityLink" = "opportunityLink",
  "developerRate" = "developerRate",
  "customerRate" = "customerRate",
  "customerRateAlternative" = "customerRateAlternative",
  "billedHours" = "billedHours",
  "ptoHours" = "ptoHours",
  "startDate" = "startDate",
  "endDate" = "endDate",
  "duration" = "duration",
  "internalStatus" = "internalStatus",
  "externalStatus" = "externalStatus",
  "externalStatusAlternative" = "externalStatusAlternative",
  "locationText" = "locationText",
  "customerContactEmail" = "customerContactEmail",
  "accountExecutiveEmail" = "accountExecutiveEmail",
  "salesRepEmail" = "salesRepEmail",
  "personalEmail" = "personalEmail",
  "turingEmail" = "turingEmail",
  "developerRecord" = "developerRecord",
  "hoursWorked" = "hoursWorked",
  "feedback" = "feedback",
}

export const TableDataKeyToNameMap: Record<TableDataKeyEnum | string, string> =
  {
    [TableDataKeyEnum.developerRecordWithLink]: "Developer",
    [TableDataKeyEnum.companyNameWithLink]: "Company",
    [TableDataKeyEnum.jobTitleWithLink]: "Title",
    [TableDataKeyEnum.opportunityLink]: "Salesforce link",
    [TableDataKeyEnum.developerRate]: "Developer rate",
    [TableDataKeyEnum.ptoHours]: "PTO hours",
    [TableDataKeyEnum.startDate]: "Start date",
    [TableDataKeyEnum.endDate]: "End date",
    [TableDataKeyEnum.duration]: "Duration",
    [TableDataKeyEnum.internalStatus]: "Internal status",
    [TableDataKeyEnum.externalStatus]: "Status",
    [TableDataKeyEnum.externalStatusAlternative]: "External status",
    [TableDataKeyEnum.customerRate]: "Rate",
    [TableDataKeyEnum.customerRateAlternative]: "Customer rate",
    [TableDataKeyEnum.locationText]: "Location",
    [TableDataKeyEnum.customerContactEmail]: "Customer contact",
    [TableDataKeyEnum.accountExecutiveEmail]: "Turing contact",
    [TableDataKeyEnum.salesRepEmail]: "Sales representative",
    [TableDataKeyEnum.personalEmail]: "Developer personal email",
    [TableDataKeyEnum.turingEmail]: "Developer turing email",
    [TableDataKeyEnum.developerRecord]: "Developer",
    [TableDataKeyEnum.billedHours]: "Hours worked",
    [TableDataKeyEnum.feedback]: "Feedback",
  };

export const TableSort: Record<TableDataKeyEnum | string, string> = {
  [TableDataKeyEnum.developerName]: "developerNameSort",
  [TableDataKeyEnum.companyName]: "companyNameSort",
  [TableDataKeyEnum.jobTitle]: "jobTitleSort",
  [TableDataKeyEnum.developerRate]: "developerRateSort",
  [TableDataKeyEnum.customerRate]: "customerRateSort",
  [TableDataKeyEnum.billedHours]: "billedHoursSort",
  [TableDataKeyEnum.startDate]: "startDateSort",
  [TableDataKeyEnum.endDate]: "endDateSort",
  [TableDataKeyEnum.duration]: "durationSort",
  [TableDataKeyEnum.internalStatus]: "internalStatusSort",
  [TableDataKeyEnum.externalStatus]: "externalStatusSort",
  [TableDataKeyEnum.locationText]: "locationSort",
  [TableDataKeyEnum.salesRepEmail]: "salesRepEmailSort",
  [TableDataKeyEnum.personalEmail]: "personalEmailSort",
  [TableDataKeyEnum.turingEmail]: "turingEmailSort",
  [TableDataKeyEnum.customerContactEmail]: "customerContactEmailSort",
  [TableDataKeyEnum.accountExecutiveEmail]: "accountExecutiveEmailSort",
};
