import { RangePickerProps } from "antd/lib/date-picker";
import moment, { Moment } from "moment";
import { ActivePicker } from "../../types/postmatch.types";

const formatStartAndEndDate = (
  startDate: string,
  endDate: string,
  dateFormat: string,
) => {
  const start = moment.utc(startDate).format(dateFormat);
  const end = moment.utc(endDate).format(dateFormat);
  return { start, end };
};

const getDefaultDate = (): any => {
  const start = moment().utc(true).startOf("month");
  const end = moment().utc(true).endOf("month");
  return [start, end];
};

export const getPlaceholderDate = (
  startDate: string,
  endDate: string,
): [string, string] => {
  const dateFormat = "MM/DD/YYYY";
  const { start, end } = formatStartAndEndDate(startDate, endDate, dateFormat);
  return [start, end];
};

export const formatRenderedDate = (startDate: string, endDate: string) => {
  const dateFormat = "MMMM D, YYYY";
  const { start, end } = formatStartAndEndDate(startDate, endDate, dateFormat);
  return start !== end ? `${start} - ${end}` : start;
};

export const getTimeLimits = () => {
  const start = moment.utc("2023-06-01", "YYYY-MM-DD").startOf("day");
  const endOfMonth = moment().utc(true).endOf("month");
  return { startLimit: start, endLimit: endOfMonth };
};

export const disabledDate: RangePickerProps["disabledDate"] = (
  calendarItem: Moment,
) => {
  const { startLimit, endLimit } = getTimeLimits();
  const calendarItemTime = calendarItem.utc().startOf("day");
  const startTimestamp = startLimit.format("X");
  const endTimestamp = endLimit.format("X");
  const calendarItemTimestamp = calendarItemTime.format("X");
  return (
    calendarItemTimestamp < startTimestamp ||
    calendarItemTimestamp >= endTimestamp
  );
};

export const getInitialDate = () => {
  const [start, end]: [Moment, Moment] = getDefaultDate();
  return { start: start.toISOString(), end: end.toISOString() };
};

export const getDefaultPickerValues = (): any => {
  return [moment().subtract(1, "month"), undefined];
};

export function weekOfMonth(date: string) {
  const momentDate = moment.utc(date);
  return momentDate.isoWeek() - momentDate.startOf("month").isoWeek() + 1;
}

export function getPopoverDateTitle(
  activePicker: string,
  start: string,
  end: string,
) {
  if (activePicker === ActivePicker.Week) {
    return `Week ${weekOfMonth(start)} - ${moment
      .utc(start)
      .format("MMMM")} ${moment.utc(start).format("YYYY")}`;
  }
  if (activePicker === ActivePicker.Month) {
    return moment.utc(start).format("MMMM YYYY");
  }
  return `${moment.utc(start).format("MM/DD/YYYY")} - ${moment
    .utc(end)
    .format("MM/DD/YYYY")}`;
}

export function getActivePickerFromQuery(query: URLSearchParams) {
  const pickerMode = query.get("pickerMode");
  if (pickerMode === ActivePicker.Week) {
    return ActivePicker.Week;
  }
  if (pickerMode === ActivePicker.Month) {
    return ActivePicker.Month;
  }
  if (pickerMode === ActivePicker.Custom) {
    return ActivePicker.Custom;
  }
  return ActivePicker.Month;
}

export function getInitialPopoverDateTitle(query: URLSearchParams) {
  const startDate = query.get("startDate");
  const endDate = query.get("endDate");
  if (!moment.utc(startDate).isValid() || !moment.utc(endDate).isValid()) {
    return moment().utc(true).format("MMMM YYYY");
  }
  const pickerFromQuery = getActivePickerFromQuery(query);
  const startDateISO = moment.utc(startDate).startOf("day").toISOString();
  const endDateISO = moment.utc(endDate).endOf("day").toISOString();
  const startOfMonthISO = moment.utc(startDate).startOf("month").toISOString();
  const endOfMonthISO = moment.utc(endDate).endOf("month").toISOString();
  if (
    pickerFromQuery === ActivePicker.Month &&
    startDateISO === startOfMonthISO &&
    endDateISO === endOfMonthISO
  ) {
    return getPopoverDateTitle(
      ActivePicker.Month,
      startOfMonthISO,
      endOfMonthISO,
    );
  }
  const startOfWeekISO = moment.utc(startDate).startOf("week").toISOString();
  const endOfWeekISO = moment.utc(endDate).endOf("week").toISOString();
  if (
    pickerFromQuery === ActivePicker.Week &&
    startDateISO === startOfWeekISO &&
    endDateISO === endOfWeekISO
  ) {
    return getPopoverDateTitle(ActivePicker.Week, startOfWeekISO, endOfWeekISO);
  }
  return getPopoverDateTitle(ActivePicker.Custom, startDateISO, endDateISO);
}

export function getMonthFromISO(date: string) {
  return moment.utc(date).startOf("day").month();
}

export function getYearFromISO(date: string) {
  return moment.utc(date).startOf("day").year();
}
