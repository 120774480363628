import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { logClickActivity } from "../../utils/activity-logs/activity-logs";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";
import { PostmatchTableLinkNewTab } from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab";
import styles from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab.module.css";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { PostmatchTableFieldActivity } from "./postmatch-table-field-activity";

export function PostmatchTableRenderLink(
  activityKey: string,
  activityAction: string,
  logActivity: PostmatchTableProps["logActivity"],
) {
  return function (value: { link: string; text: string }) {
    const prefix = process.env.REACT_APP_POSTMATCH_URL_PREFIX;
    const { link, text } = value;

    const onClickField = () => {
      logClickActivity(activityKey, activityAction, link, logActivity);
    };

    return link && text ? (
      prefix ? (
        <a
          href={`${prefix}${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <PostmatchTableFieldActivity
            onClickField={onClickField}
            text={text}
          />
        </a>
      ) : process.env.REACT_APP_USE_ROUTER ? (
        <Router>
          <PostmatchTableLinkNewTab link={link}>
            <PostmatchTableFieldActivity
              onClickField={onClickField}
              text={text}
            />
          </PostmatchTableLinkNewTab>
        </Router>
      ) : (
        <PostmatchTableLinkNewTab link={link}>
          <PostmatchTableFieldActivity
            onClickField={onClickField}
            text={text}
          />
        </PostmatchTableLinkNewTab>
      )
    ) : (
      <div>{getPlaceholderValue(text)}</div>
    );
  };
}
