import moment from "moment";
import { ActivePicker, Company } from "../../types/postmatch.types";
import { getInitialDate, getTimeLimits } from "../dates/dates";

export function getCompanyIndexQuery(
  companies: Company[],
  salesforceAccountIdQuery: string | null,
) {
  if (!salesforceAccountIdQuery) {
    return null;
  }
  return companies.findIndex(
    ({ salesforceAccountId }) =>
      salesforceAccountIdQuery === salesforceAccountId,
  );
}

export function addPostmatchQuerySalesforceAccountId(
  query: any,
  history: any,
  salesforceAccountId: string | null,
) {
  const querySalesforceAccountId = salesforceAccountId || "";
  query.set("salesforceAccountId", querySalesforceAccountId);
  history.replace({
    pathname: history.location.pathname,
    search: `?${query.toString()}`,
  });
}

export function addPostmatchQuery(
  query: URLSearchParams,
  history: any,
  startDateISO: string,
  endDateISO: string,
  pickerMode: string,
  salesforceAccountId: string | null = null,
  isSalesforceAccountIdVisible = false,
) {
  const queryStartDate = moment.utc(startDateISO).format("YYYY-MM-DD");
  const queryEndDate = moment.utc(endDateISO).format("YYYY-MM-DD");
  if (isSalesforceAccountIdVisible) {
    const querySalesforceAccountId = salesforceAccountId || "";
    query.set("salesforceAccountId", querySalesforceAccountId);
  }
  query.set("startDate", queryStartDate);
  query.set("endDate", queryEndDate);
  query.set("pickerMode", pickerMode);
  history.replace({
    pathname: history.location.pathname,
    search: `?${query.toString()}`,
  });
}

function isPickerWeekFromQueryValid(startDate: string, endDate: string) {
  const startOfWeek = moment.utc(startDate).startOf("week").toISOString();
  const endOfWeek = moment.utc(endDate).endOf("week").toISOString();
  return startDate === startOfWeek && endDate === endOfWeek;
}

function isPickerMonthFromQueryValid(startDate: string, endDate: string) {
  const startOfMonth = moment.utc(startDate).startOf("month").toISOString();
  const endOfMonth = moment.utc(endDate).endOf("month").toISOString();
  return startDate === startOfMonth && endDate === endOfMonth;
}

function isPickerCustomFromQueryValid(startDate: string, endDate: string) {
  return moment.utc(startDate).isValid() && moment.utc(endDate).isValid();
}

export function isPickerFromQueryValid(
  activePicker: string | null,
  startDate: string,
  endDate: string,
) {
  if (activePicker === ActivePicker.Week) {
    return isPickerWeekFromQueryValid(startDate, endDate);
  }
  if (activePicker === ActivePicker.Month) {
    return isPickerMonthFromQueryValid(startDate, endDate);
  }
  if (activePicker === ActivePicker.Custom) {
    return isPickerCustomFromQueryValid(startDate, endDate);
  }
  return false;
}

export function getInitialDateFromQuery(query: URLSearchParams, history: any) {
  const startQuery = query.get("startDate");
  const endQuery = query.get("endDate");
  const startDate = moment.utc(startQuery).startOf("day");
  const endDate = moment.utc(endQuery).endOf("day");
  const { startLimit } = getTimeLimits();
  const startDateTimestamp = startDate.format("X");
  const endDateTimestamp = endDate.format("X");
  const startLimitTimestamp = startLimit.format("X");
  const pickerMode = query.get("pickerMode");
  if (
    startQuery &&
    endQuery &&
    startDate.isValid() &&
    endDate.isValid() &&
    startDateTimestamp >= startLimitTimestamp &&
    startDateTimestamp < endDateTimestamp &&
    endDateTimestamp > startLimitTimestamp &&
    isPickerFromQueryValid(
      pickerMode,
      startDate.toISOString(),
      endDate.toISOString(),
    )
  ) {
    return {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      pickerMode,
    };
  }
  const defaultDateRange = getInitialDate();
  const defaultPicker = { pickerMode: ActivePicker.Month };
  addPostmatchQuery(
    query,
    history,
    defaultDateRange.start,
    defaultDateRange.end,
    defaultPicker.pickerMode,
  );
  return { ...defaultDateRange, ...defaultPicker };
}

export function getQueryDatesInISO(query: URLSearchParams) {
  return {
    startDateFromQueryISO: moment
      .utc(query.get("startDate"))
      .startOf("day")
      .toISOString(),
    endDateFromQueryISO: moment
      .utc(query.get("endDate"))
      .endOf("day")
      .toISOString(),
  };
}
