import React from "react";
import { Feedback } from "../../types/postmatch.types";
import { PostmatchButton } from "../postmatch-button/postmatch-button";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";

export function PostmatchTableRenderFeedback(
  onClickFeedbackTable: PostmatchTableProps["onClickFeedbackTable"],
) {
  return function (feedback: Feedback) {
    const onClick = () => {
      if (typeof onClickFeedbackTable === "function") {
        onClickFeedbackTable(feedback);
      }
    };
    return (
      <PostmatchButton onClick={onClick} fontSize={12} width={70} height={32}>
        Submit
      </PostmatchButton>
    );
  };
}
