import React from "react";
import { Button } from "antd";
import { PostmatchButtonProps } from "./postmatch-button.types";

export function PostmatchButton({
  children,
  onClick,
  isLoading = false,
  fontSize = 16,
  width = 80,
  height = 32,
}: PostmatchButtonProps) {
  return (
    <Button
      onClick={onClick}
      loading={isLoading}
      type="primary"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 6,
        backgroundColor: "#2e6edf",
        width: isLoading ? width + 16 : width,
        height,
        fontSize,
      }}
    >
      {children}
    </Button>
  );
}
