import React from "react";
import { DeveloperRecord } from "../../types/postmatch.types";
import { PostmatchTableDeveloperActivity } from "./postmatch-table-developer-activity";

export function PostmatchTableRenderDeveloper(onClickDeveloperOpenDDP: any) {
  return (value: {
    text: string;
    link: string;
    imageUrl: string;
    developerId: string | number | undefined;
    developerIdHash: string | undefined;
  }) => {
    const developerRecord: DeveloperRecord = {
      name: value.text,
      img: value.imageUrl,
      developerId: value.developerId,
      developerIdHash: value.developerIdHash,
    };
    const onClickDeveloper = () => {
      if (typeof onClickDeveloperOpenDDP === "function") {
        onClickDeveloperOpenDDP(developerRecord);
      }
    };
    return (
      <PostmatchTableDeveloperActivity
        developerRecord={developerRecord}
        onClickDeveloper={onClickDeveloper}
      />
    );
  };
}
