import axios, { AxiosRequestConfig } from "axios";
import {
  Feedback,
  GetBulkEngagementsResponseData,
  ProductSurface,
} from "../../types/postmatch.types";
import { flatArray } from "../../utils/helpers/helpers";
import { DEFAULT_NUM_IDS_PER_REQUEST } from "./postmatch-fetch.constants";

function preprocessMissingDataMessage(
  allEngagementsResponse: GetBulkEngagementsResponseData[],
) {
  return allEngagementsResponse[0].data.error;
}

function preprocessActivityPayload(
  engagements: any,
  startDate: string,
  endDate: string,
  startTime: number,
) {
  const salesforceAccountIds: any = [];
  const engagementsEntry: any = [];
  engagements.forEach((engagement: any) => {
    if (engagement?.developers?.length) {
      const salesforceAccountId = engagement?.salesforceAccountId;
      if (salesforceAccountId) {
        salesforceAccountIds.push(salesforceAccountId);
      }
      engagement?.developers?.forEach((developer: any) => {
        const developerId = developer?.id;
        const oppId = developer?.opportunityId;
        if (developerId) {
          engagementsEntry.push({ developerId, ...(oppId && { oppId }) });
        }
      });
    }
  });
  return {
    salesforceAccountIds,
    dateFilter: { startDate, endDate },
    engagements: engagementsEntry,
    searchTime: (new Date().getTime() - startTime) / 1000,
    page: window?.location?.href || "",
  };
}

export function preprocessBulkEngagements(
  allEngagementsResponse: GetBulkEngagementsResponseData[],
) {
  const companyMatrix = allEngagementsResponse.map(
    (item) => item.data.companies,
  );
  return flatArray(companyMatrix);
}

export async function fetchCompanyList(requestService: any) {
  const companyListEndpoint =
    "postmatch/v1/companies?sortBy=id&sortOrder=ASC&searchBy=company&searchValue=&pageNumber=1&pageSize=10000";
  const companyListResponse: any = await requestService.get(
    companyListEndpoint,
  );
  const companies = companyListResponse.data.companies.filter(
    ({ name, salesforceAccountId }: any) => name && salesforceAccountId,
  );
  const companyIds = companies.map(({ id }: any) => id);
  const salesforceAccountIds = companies.map(
    ({ salesforceAccountId }: any) => salesforceAccountId,
  );
  return { companies, companyIds, salesforceAccountIds };
}

export async function fetchEngagements(
  requestService: any,
  startDate: string,
  endDate: string,
  salesforceAccountIds: string[],
  productSurface: ProductSurface,
  numOfIdsPerRequest = DEFAULT_NUM_IDS_PER_REQUEST,
) {
  const postmatchAPIs: any[] = [];
  for (let i = 0; i < salesforceAccountIds.length; i += numOfIdsPerRequest) {
    const limit = Math.min(i + numOfIdsPerRequest, salesforceAccountIds.length);
    const slicedSalesforceAccountIds = salesforceAccountIds.slice(i, limit);
    const bulkEngagementsEndpoint = `postmatch/v1/engagements/get-bulk`;
    const body = {
      salesforceAccountIds: slicedSalesforceAccountIds,
      startDate,
      endDate,
      pageSize: 1,
      pageNumber: 1,
      productSurface,
    };
    const bulkEngagementsRequest = requestService.post(
      bulkEngagementsEndpoint,
      body,
    );
    postmatchAPIs.push(bulkEngagementsRequest);
  }
  const startTime = new Date().getTime();
  const allEngagementsResponse: any[] = await Promise.all(postmatchAPIs);
  const engagements = preprocessBulkEngagements(allEngagementsResponse);
  return {
    engagements,
    missingDataMessage: preprocessMissingDataMessage(allEngagementsResponse),
    activityPayload: preprocessActivityPayload(
      engagements,
      startDate,
      endDate,
      startTime,
    ),
  };
}

export async function fetchEngagementsCompanyDevelopers(
  requestService: any,
  startDate: string,
  endDate: string,
  id: number,
  productSurface: ProductSurface,
) {
  const endpoint = `postmatch/v1/engagements/get-bulk`;
  const body = {
    companyIds: [id],
    startDate,
    endDate,
    pageSize: 1,
    pageNumber: 1,
    productSurface,
  };
  const startTime = new Date().getTime();
  const engagementsResponse: any = await requestService.post(endpoint, body);
  const engagements = engagementsResponse.data.companies;
  return {
    engagements,
    missingDataMessage: engagementsResponse.data.error,
    activityPayload: preprocessActivityPayload(
      engagements,
      startDate,
      endDate,
      startTime,
    ),
  };
}

export async function fetchEngagementsSelfServeUser(
  requestService: any,
  startDate: string,
  endDate: string,
  selfServeUserId: string,
  selfServeView: number,
) {
  const endpoint = `self-serve-user/${selfServeUserId}/engagements?selfServeView=${selfServeView}&startDate=${startDate}&endDate=${endDate}`;
  const startTime = new Date().getTime();
  const engagementsResponse: any = await requestService.get(endpoint);
  const engagements = engagementsResponse.data.companies;
  return {
    engagements,
    missingDataMessage: engagementsResponse.data.error,
    activityPayload: preprocessActivityPayload(
      engagements,
      startDate,
      endDate,
      startTime,
    ),
  };
}

export async function fetchEngagementsSelfServe(
  requestService: any,
  startDate: string,
  endDate: string,
) {
  const url = `engagements?startDate=${startDate}&endDate=${endDate}`;
  const startTime = new Date().getTime();
  const engagementsResponse: any = await requestService.get(url);
  const engagements = engagementsResponse.data.companies;
  return {
    engagements,
    missingDataMessage: engagementsResponse.data.error,
    activityPayload: preprocessActivityPayload(
      engagements,
      startDate,
      endDate,
      startTime,
    ),
  };
}

export async function submitPostmatchFeedback(
  endpoint: string,
  token: string | null,
  body: Feedback,
) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    timeout: 30000,
  };
  await axios.post(endpoint, body, config);
}
