import moment from "moment";
import {
  CompanyItem,
  Developer,
  Location,
  User,
} from "../../types/postmatch.types";
import { convertFractionalHoursFixed } from "../convert-fractional-rates/convert-fractional-rates";
import {
  PLACEHOLDER,
  getPlaceholderValue,
  isValueValid,
} from "../get-placeholder-value/get-placeholder-value";
import { END_DATE_CURRENTLY_WORKING } from "./parse-developer-list.constants";
import { getPrefixURL } from "../url/get-prefix-url/get-prefix-url";

function insertSingleCompanyName(
  developers: Developer[],
  companyName: string,
  id: number,
  salesRepresentative: User,
) {
  return developers.map((developer, index) => ({
    ...developer,
    companyName,
    id: `0x${index}`,
    companyId: id,
    developerId: developer?.id,
    salesRepresentative,
  }));
}

function insertCompanyName(allEngagements: CompanyItem[]) {
  return allEngagements.map((postmatchCompanyItem) => ({
    ...postmatchCompanyItem,
    developers: postmatchCompanyItem?.developers?.map((developer) => ({
      ...developer,
      companyName: postmatchCompanyItem?.name,
      companyId: postmatchCompanyItem?.id,
      developerId: developer?.id,
      salesRepresentative: postmatchCompanyItem?.salesRepresentative,
    })),
  }));
}

function combineDevelopers(postmatchDeveloperList: CompanyItem[]) {
  const acc: any = [];
  for (let i = 0; i < postmatchDeveloperList.length; i += 1) {
    for (let j = 0; j < postmatchDeveloperList[i].developers?.length; j += 1) {
      acc.push({ ...postmatchDeveloperList[i].developers[j], id: `${i}x${j}` });
    }
  }
  return acc;
}

function formatLocation(location: Location | undefined) {
  return getPlaceholderValue(location?.country);
}

function capitalizeFirstLetter(text: string) {
  return `${text.replace(text.charAt(0), text.charAt(0).toUpperCase())}`;
}

function formatDevelopers(developers: Developer[]) {
  return developers.map((developer) => {
    const {
      location,
      email,
      developerBillingRate,
      customerBillingRate,
      status,
      engagement,
      billedHours,
      companyId,
      developerId,
      companyName,
      name: developerName,
      avatarUrl,
      accountExecutive,
      salesRepresentative,
      developerIdHash,
    } = developer;

    const { rateText: developerRate, rate: developerRateSort } =
      developerBillingRate || {};

    const { rateText: customerRateText, rate: customerRateSort } =
      customerBillingRate || {};

    const engagementStartDate = engagement?.startDate;
    const engagementEndDate = engagement?.endDate;

    const startDate = moment(engagementStartDate, "YYYY-MM-DD");
    const endDate = moment(engagementEndDate, "YYYY-MM-DD");

    const formattedStartDate = startDate.isValid()
      ? startDate.format("MM/DD/YYYY")
      : PLACEHOLDER;
    const formattedEndDate = endDate.isValid()
      ? endDate.format("MM/DD/YYYY")
      : END_DATE_CURRENTLY_WORKING;

    const startDateSort = startDate.isValid()
      ? parseInt(startDate.format("X"), 10)
      : null;
    const endDateSort = endDate.isValid()
      ? parseInt(endDate.format("X"), 10)
      : null;

    const internal = getPlaceholderValue(status?.internal);
    const external = getPlaceholderValue(status?.external);

    const internalStatus = capitalizeFirstLetter(internal);
    const internalStatusSort = internalStatus.toLowerCase();

    const externalStatus = capitalizeFirstLetter(external);
    const externalStatusSort = externalStatus.toLowerCase();

    const personalEmail = getPlaceholderValue(
      email?.personal?.replace(/\s/, ""),
    );
    const personalEmailSort = personalEmail.toLowerCase();

    const turingEmail = getPlaceholderValue(email?.turing);
    const turingEmailSort = getPlaceholderValue(turingEmail);

    const companyPageLink = companyId ? `/company/${companyId}` : "";
    const companyNameWithLink = {
      text: companyName || "",
      link: companyPageLink,
    };
    const companyNameSort = getPlaceholderValue(companyName).toLowerCase();

    const jobId = engagement?.job?.id;
    const jobPageLink = jobId ? `/job/${jobId}` : "";
    const jobTitle = engagement?.job?.title || "";
    const jobTitleWithLink = {
      text: jobTitle,
      link: jobPageLink,
    };
    const jobTitleSort = jobTitle.toLowerCase();

    const developerPageLink = developerId ? `/developer/${developerId}` : "";

    const developerRecord = {
      text: developerName,
      link: developerPageLink,
      imageUrl: avatarUrl
        ? `https://storage.googleapis.com/turing_developers/avatar/${avatarUrl}`
        : "",
      developerId,
      developerIdHash,
    };

    const accountExecutiveEmail = getPlaceholderValue(accountExecutive?.email);
    const accountExecutiveEmailSort = accountExecutiveEmail.toLowerCase();

    const customerContactEmail = getPlaceholderValue(
      engagement?.customerHiringManager?.email,
    );
    const customerContactEmailSort = customerContactEmail.toLowerCase();

    const salesRepEmail = getPlaceholderValue(salesRepresentative?.email);
    const salesRepEmailSort = salesRepEmail.toLowerCase();

    const formattedLocation = formatLocation(location);
    const locationSort = formattedLocation.toLowerCase();

    const formattedBilledHours = convertFractionalHoursFixed(billedHours, true);
    const billedHoursSort =
      typeof billedHours === "string" ? parseFloat(billedHours) : billedHours;

    const opportunityLink = developer?.opportunityLink || "";

    const normalizedDeveloperName = getPlaceholderValue(developerName);
    const developerNameSort = normalizedDeveloperName.toLowerCase();

    const formattedDuration = engagement?.formattedDuration;
    const duration = engagement?.duration;
    const normalizedFormattedDuration = getPlaceholderValue(formattedDuration);
    const durationSort = isValueValid(duration) ? duration : null;

    const prefixURL = getPrefixURL();

    const feedback = {
      developerName,
      developerPageLink: `${prefixURL}${developerPageLink}`,
      companyName,
      companyPageLink: `${prefixURL}${companyPageLink}`,
      jobTitle,
      jobPageLink: `${prefixURL}${jobPageLink}`,
      salesforceLink: opportunityLink,
    };

    return {
      ...developer,

      developerRecordWithLink: developerRecord,
      developerPageLink,
      developerName,
      developerNameSort,
      developerId,

      companyNameWithLink,
      companyPageLink,
      companyName,
      companyNameSort,

      jobTitleWithLink,
      jobPageLink,
      jobTitle,
      jobTitleSort,

      developerRate: getPlaceholderValue(developerRate),
      developerRateSort,

      customerRate: customerRateText,
      customerRateSort,

      billedHours: formattedBilledHours,
      billedHoursSort,

      internalStatus,
      internalStatusSort,
      externalStatus,
      externalStatusSort,

      startDate: formattedStartDate,
      startDateSort,

      endDate: formattedEndDate,
      endDateSort,

      duration: normalizedFormattedDuration,
      durationSort,

      locationText: formattedLocation,
      locationWithFilter: formattedLocation,
      locationSort,

      salesRepEmail,
      salesRepEmailSort,

      personalEmail,
      personalEmailSort,

      turingEmail,
      turingEmailSort,

      developerRecord,

      billingStatus: externalStatus,

      accountExecutiveEmail,
      accountExecutiveEmailSort,

      customerContactEmail,
      customerContactEmailSort,

      opportunityLink,

      feedback,
    };
  });
}

export function parseDevelopersFromSingleCompany(
  developers: Developer[],
  companyName: string,
  id: number,
  salesRepresentative: User,
) {
  return formatDevelopers(
    insertSingleCompanyName(developers, companyName, id, salesRepresentative),
  );
}

export function parseDevelopersFromAllCompanies(engagements: CompanyItem[]) {
  return formatDevelopers(combineDevelopers(insertCompanyName(engagements)));
}
