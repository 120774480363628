import React from "react";
import { PostmatchTableFieldActivityProps } from "./postmatch-table-field-activity.types";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";

export function PostmatchTableFieldActivity({
  onClickField,
  text,
}: PostmatchTableFieldActivityProps) {
  return <div onClick={onClickField}>{getPlaceholderValue(text)}</div>;
}
