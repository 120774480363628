import React from "react";
import classNames from "classnames";
import { PostmatchSummaryBlocksItem } from "../postmatch-summary-blocks-item/postmatch-summary-blocks-item";
import styles from "./postmatch-summary-blocks.module.css";

export function PostmatchSummaryBlocks({
  postmatchCompany,
  isLoading,
  styleLeft,
  enableHoursBilled,
}: any) {
  return (
    <div
      className={classNames(styles.summaryBlocks, {
        [styles.left]: styleLeft,
      })}
    >
      <PostmatchSummaryBlocksItem
        name="Active contractors"
        value={postmatchCompany?.totalActiveDevelopers}
        isLoading={isLoading}
        styleLeft={styleLeft}
      />
      {enableHoursBilled && (
        <PostmatchSummaryBlocksItem
          name="Hours billed"
          value={postmatchCompany?.billedHours}
          isLoading={isLoading}
          styleLeft={styleLeft}
        />
      )}
      <PostmatchSummaryBlocksItem
        name="Engagement starts"
        value={postmatchCompany?.totalEngagementsStarts}
        isLoading={isLoading}
        styleLeft={styleLeft}
      />
      <PostmatchSummaryBlocksItem
        name="Engagement ends"
        value={postmatchCompany?.totalEngagementsStops}
        isLoading={isLoading}
        styleLeft={styleLeft}
      />
    </div>
  );
}
