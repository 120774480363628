import React from "react";
import { PLACEHOLDER } from "../../utils/get-placeholder-value/get-placeholder-value";
import { getCellClassName } from "./postmatch-table-render.utils";

export function PostmatchTableRenderDuration() {
  return function (text: string) {
    const cellClassName = getCellClassName(text);
    const words = text?.split(/\s/);
    return words ? (
      <div className={cellClassName}>
        {words.map((word, index) => {
          return index === 1 || index === 3 ? (
            // Fragment shorthand not supported on current microbundle setup
            // Ok using index since order can't change
            <React.Fragment key={index}>
              <span>{word}</span> <br />
            </React.Fragment>
          ) : (
            <React.Fragment key={index}>
              <span>{word}</span>{" "}
            </React.Fragment>
          );
        })}
      </div>
    ) : (
      <div className={cellClassName}>{PLACEHOLDER}</div>
    );
  };
}
