import React from "react";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";
import { ReactComponent as TabLink } from "./newtablink.svg";

export function PostmatchTableRenderSalesforce() {
  return function (link: string) {
    return link ? (
      <a target="_blank" href={link} rel="noreferrer">
        <TabLink />
      </a>
    ) : (
      <div>{getPlaceholderValue("")}</div>
    );
  };
}
