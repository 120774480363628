import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { DeveloperRecord } from "../../types/postmatch.types";
import { logClickActivity } from "../../utils/activity-logs/activity-logs";
import { PostmatchTableLinkNewTab } from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab";
import styles from "../postmatch-table-link-new-tab/postmatch-table-link-new-tab.module.css";
import { PostmatchTableProps } from "../postmatch-table/postmatch-table.types";
import { PostmatchTableDeveloperActivity } from "./postmatch-table-developer-activity";

export function PostmatchTableRenderLinkDeveloper(
  activityKey: string,
  activityAction: string,
  logActivity: PostmatchTableProps["logActivity"],
) {
  return (value: {
    text: string;
    link: string;
    imageUrl: string;
    developerId: number | undefined;
    developerIdHash: string | undefined;
  }) => {
    const prefix = process.env.REACT_APP_POSTMATCH_URL_PREFIX;
    const link = value.link;
    const developerRecord: DeveloperRecord = {
      name: value.text,
      img: value.imageUrl,
      developerId: value.developerId,
      developerIdHash: value.developerIdHash,
    };

    const onClickDeveloper = () => {
      logClickActivity(activityKey, activityAction, link, logActivity);
    };

    return link ? (
      prefix ? (
        <a
          href={`${prefix}${link}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          <PostmatchTableDeveloperActivity
            developerRecord={developerRecord}
            onClickDeveloper={onClickDeveloper}
          />
        </a>
      ) : process.env.REACT_APP_USE_ROUTER ? (
        <Router>
          <PostmatchTableLinkNewTab link={link}>
            <PostmatchTableDeveloperActivity
              developerRecord={developerRecord}
              onClickDeveloper={onClickDeveloper}
            />
          </PostmatchTableLinkNewTab>
        </Router>
      ) : (
        <PostmatchTableLinkNewTab link={link}>
          <PostmatchTableDeveloperActivity
            developerRecord={developerRecord}
            onClickDeveloper={onClickDeveloper}
          />
        </PostmatchTableLinkNewTab>
      )
    ) : (
      <PostmatchTableDeveloperActivity
        developerRecord={developerRecord}
        onClickDeveloper={onClickDeveloper}
      />
    );
  };
}
