import React from "react";
import { UserOutlined } from "@ant-design/icons";
import styles from "./postmatch-table-render.module.css";
import { getPlaceholderValue } from "../../utils/get-placeholder-value/get-placeholder-value";

export function PostmatchTableDeveloperActivity({
  developerRecord,
  onClickDeveloper,
}: any) {
  const name = developerRecord?.name;
  const img = developerRecord?.img;
  const developerId = developerRecord?.developerId;
  const developerIdHash = developerRecord?.developerIdHash;

  const isDeveloperClickable = name && developerId && developerIdHash;

  const developerRecordClassName = `${styles.developerRecord} ${
    isDeveloperClickable ? styles.developerRecordButton : ""
  }`;

  const nameClassName = `${styles.name} ${
    isDeveloperClickable ? styles.nameButton : ""
  }`;

  return (
    <div
      className={developerRecordClassName}
      onClick={onClickDeveloper}
      data-testid="postmatch-developer"
    >
      {img ? (
        <img src={img} />
      ) : (
        <div className={styles.placeholder}>
          <UserOutlined />
        </div>
      )}
      <div className={nameClassName}>{getPlaceholderValue(name)}</div>
    </div>
  );
}
