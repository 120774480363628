import {
  CompanyItem,
  GetCompaniesResponse,
  SummaryDetails,
  User,
} from "../../types/postmatch.types";
import { convertFractionalHoursFixed } from "../convert-fractional-rates/convert-fractional-rates";
import {
  convertToUSD,
  convertToUSDUnits,
} from "../convert-to-usd/convert-to-usd";
import { getPlaceholderValue } from "../get-placeholder-value/get-placeholder-value";
import {
  parseDevelopersFromAllCompanies,
  parseDevelopersFromSingleCompany,
} from "../parse-developer-list/parse-developer-list";

export function parseCompanyList(
  companyList: GetCompaniesResponse["companies"] | null,
) {
  return (companyList || []).map(
    ({ id, name, salesforceAccountId }, index) => ({
      id,
      label: name,
      value: index,
      salesforceAccountId,
    }),
  );
}

export function parseEngagementsSingleCompany(engagements: CompanyItem) {
  const {
    developers,
    name,
    totalActiveDevelopers,
    billedHours,
    moneySpent,
    totalEngagements,
    id,
    salesRepresentative,
  } = engagements;
  const developerList = parseDevelopersFromSingleCompany(
    developers || [],
    name,
    id as number,
    salesRepresentative as User,
  );
  const summaryDetails: SummaryDetails = {};
  if (name) {
    const totalEngagementsStarts = getPlaceholderValue(
      totalEngagements?.starts,
    );
    const totalEngagementsStops = getPlaceholderValue(totalEngagements?.stops);
    summaryDetails[name] = {
      totalActiveDevelopers,
      billedHours: convertFractionalHoursFixed(billedHours, false),
      moneySpent: convertToUSDUnits(moneySpent),
      moneySpentTitle: convertToUSD(moneySpent),
      totalEngagementsStarts,
      totalEngagementsStops,
      salesRepresentative,
    };
  }
  return { developers: developerList, summaryDetails };
}

export function parseAllEngagements(engagements: CompanyItem[]) {
  const developerList = parseDevelopersFromAllCompanies(engagements);

  const initialSummaryDetails: SummaryDetails = {};
  const summaryDetails = engagements.reduce((acc, curr) => {
    const {
      totalActiveDevelopers,
      billedHours,
      moneySpent,
      totalEngagements,
      salesRepresentative,
      name,
    } = curr;
    const totalEngagementsStarts = getPlaceholderValue(
      totalEngagements?.starts,
    );
    const totalEngagementsStops = getPlaceholderValue(totalEngagements?.stops);
    if (name) {
      acc[name] = {
        totalActiveDevelopers,
        billedHours: convertFractionalHoursFixed(billedHours, false),
        moneySpent: convertToUSDUnits(moneySpent),
        moneySpentTitle: convertToUSD(moneySpent),
        totalEngagementsStarts,
        totalEngagementsStops,
        salesRepresentative,
      };
    }
    return acc;
  }, initialSummaryDetails);

  return { developers: developerList, summaryDetails };
}

export function parseEngagementsSingleId(engagements: CompanyItem[]) {
  const engagementsCompany = engagements?.[0] || ({} as CompanyItem);
  const {
    developers,
    name,
    totalActiveDevelopers,
    billedHours,
    moneySpent,
    totalEngagements,
    id,
    salesRepresentative,
  } = engagementsCompany;
  const developerList = parseDevelopersFromSingleCompany(
    developers || [],
    name,
    id as number,
    salesRepresentative as User,
  );
  const totalEngagementsStarts = getPlaceholderValue(totalEngagements?.starts);
  const totalEngagementsStops = getPlaceholderValue(totalEngagements?.stops);
  const companyDetails = {
    totalActiveDevelopers,
    billedHours: convertFractionalHoursFixed(billedHours, false),
    moneySpent: convertToUSDUnits(moneySpent),
    moneySpentTitle: convertToUSD(moneySpent),
    totalEngagementsStarts,
    totalEngagementsStops,
    salesRepresentative,
    name,
  };
  const companyInfo = {
    companyName: name,
    salesRep: salesRepresentative?.email,
  };
  return {
    developers: developerList,
    companyDetails,
    companyName: name,
    companyInfo,
  };
}
